<!--  ------------------Page Heading Start------------- -->

<div class="page_heading">
  <div class="container">
    <h2>About Us</h2>
  </div>
</div>

<!-- ------------------Page Heading End------------- -->
<!-- ------------------Video Section End------------- -->
<!-- 
<section >
  
    <video width="100%" controls autoplay="true">
     <source src="assets/images/video.mp4" type="video/mp4">
     <source src="assets/images/video.ogg" type="video/ogg">
   </video>
   
   
   </section> -->

<!-- -------------------------Video Section Start----------------- -->

<!-- -------------------------Intro Section Start----------------- -->

<section class="mt-2" id="abt_intro">
  <div class="container">
    <!-- <div class="intro_holder">
      <div class="row">
        <div class="col-lg-3 mt-3 text-center">
          <h2 class="text-center fs-40">2012</h2>
          <span class="text-center fs-5 fw-800"
            >the Company was Established.</span
          >
        </div>
        <div class="col-lg-3 mt-3 text-center">
          <h2 class="text-center fs-40">1000+</h2>
          <span class="text-center fs-5 fw-800"
            >Internet Software Products for Market audience</span
          >
        </div>
        <div class="col-lg-3 mt-3 text-center">
          <h2 class="text-center fs-40">10000+</h2>
          <span class="text-center fs-5 fw-800"> Customers </span>
        </div>
        <div class="col-lg-3 text-center mt-3">
          <h2 class="fs-40">100+</h2>
          <span class="fs-5 fw-800"
            >Business partners (Growing at ₹₹₹% per Annum)</span
          >
        </div>
        <p class="text-center mt-5 fw-bold">
          At ResilienceSoft, we're a team of innovators and problem-solvers
          passionate about harnessing technology to drive business growth and
          success. With over a decade of experience in software development and
          web design, we've established ourselves as a trusted partner for
          businesses seeking cutting-edge solutions. Our journey began with a
          simple yet powerful idea: to empower organizations to reach their full
          potential through technology. Today, we're proud to have worked with
          numerous clients across various industries, delivering tailored
          solutions that meet
        </p>
      </div>
    </div> -->
    <div class="row counter-box text-center pt-5">
      <div class="col-lg-3 col-md-3 counter_item">
        <div class="about-nubmber-img">
          <img
            loading="lazy"
            class="counter_icon"
            src="assets/images/served.webp"
            alt="served"
          />
        </div>
        <div>
          <p>
            <span class="counter">
              <app-counting-number [targetValue]="2012"></app-counting-number>
            </span>
          </p>
        </div>
        <span class="counter-desc">The Company was Established.</span>
      </div>
      <div class="col-lg-3 col-md-3 counter_item">
        <div class="about-nubmber-img">
          <img
            loading="lazy"
            class="counter_icon"
            src="assets/images/projects.webp"
            alt="projects"
          />
        </div>
        <div>
          <p>
            <span class="counter">
              <app-counting-number
                [targetValue]="500"
              ></app-counting-number> </span
            ><span class="counter_plus">+</span>
          </p>
        </div>
        <span class="counter-desc"
          >Internet Software Products for Market audience</span
        >
      </div>
      <div class="col-lg-3 col-md-3 counter_item">
        <div class="about-nubmber-img">
          <img
            loading="lazy"
            class="counter_icon"
            src="assets/images/experts.webp"
            alt="experts"
          />
        </div>
        <div>
          <p>
            <span class="counter">
              <app-counting-number
                [targetValue]="250"
              ></app-counting-number></span
            ><span class="counter_plus">+</span>
          </p>
        </div>
        <span class="counter-desc">Customers</span>
      </div>
      <div class="col-lg-3 col-md-3 counter_item">
        <div class="about-nubmber-img">
          <img
            loading="lazy"
            class="counter_icon"
            src="assets/images/groth.webp"
            alt="groth"
          />
        </div>
        <div>
          <p>
            <span class="counter"
              ><app-counting-number
                [targetValue]="20"
              ></app-counting-number></span
            ><span class="counter_plus">+</span>
          </p>
        </div>
        <span class="counter-desc"
          >Business partners</span
        >
      </div>
    </div>
    <div class="intro">
      <p class="text-justify mt-5 pt-3 pb-2 fw-bold about-para">
        At ResilienceSoft, we're a team of innovators and problem-solvers
        passionate about harnessing technology to drive business growth and
        success. With over a decade of experience in software development and
        web design, we've established ourselves as a trusted partner for
        businesses seeking cutting-edge solutions. Our journey began with a
        simple yet powerful idea: to empower organizations to reach their full
        potential through technology. Today, we're proud to have worked with
        numerous clients across various industries, delivering tailored
        solutions that meet
      </p>
    </div>
  </div>
</section>

<!-- -------------------------Strength Start----------------- -->
<!-- <section id="abt_intro">
  <div class="container">
    <div class="intro_holder our-strengths">
      <div class="white  text-center">

        <div>
          <div class="row">
            <div class="col-5">
              <div class="imgage">
                <img src="assets/images/our_strength.png" />
              </div>
            </div>
            <div class="col-7 pt-5">
              <h2>Our Strengths</h2>
              <div class="strenghts">
                <span class="circle_red"></span>
                <p class="fs-5">
                  Experienced team of software development professionals
                </p>
              </div>
              <div class="strenghts">
                <span class="circle_red"></span>
                <p class="fs-5">
                  Custom programming skills in .NET, XML, and more
                </p>
              </div>
              <div class="strenghts">
                <span class="circle_red"></span>
                <p class="fs-5">
                  Consistent delivery of high-quality solutions
                </p>
              </div>
              <div class="strenghts">
                <span class="circle_red"></span>
                <p class="fs-5">
                  Empowering skilled personnel to drive innovation
                </p>
              </div>
              <div class="strenghts">
                <span class="circle_red"></span>
                <p class="fs-5">
                  Transparency and reliability in our work culture and
                  procedures
                </p>
              </div>
              <div class="strenghts">
                <span class="circle_red"></span>
                <p class="fs-5">
                  Agile methodology to adapt to changing requirements
                </p>
              </div>
            </div> -->
<!-- <div class="strength col-lg-3">
              <span
                >Experienced team of software development professionals</span
              >
            </div>
            <div class="strength col-lg-3">
              <span>Custom programming skills in .NET, XML, and more</span>
            </div>
            <div class="strength col-lg-3">
              <span>Consistent delivery of high-quality solutions</span>
            </div>
            <div class="strength col-lg-3">
              <span>Empowering skilled personnel to drive innovation</span>
            </div>
            <div class="strength col-lg-3">
              <span>
                Transparency and reliability in our work culture and procedures
              </span>
            </div>
            <div class="strength col-lg-3">
              <span>Agile methodology to adapt to changing requirements</span>
            </div>
            <div class="strength col-lg-3">
              <span>Continuous learning and improvement approach</span>
            </div> -->
<!-- </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="mt-5 pt-5 mb-3">
  <div class="container">
    <h2 class="ab_sec_heading text-center">
      Business Partners Around The World
    </h2>
    <div id="countries">
      <ngx-slick-carousel
        class="constant-simple-slider"
        #slickModal="slick-carousel"
        [config]="contriesConfig"
      >
        <div
          ngxSlickItem
          *ngFor="let country of countries"
          class="slide mt-5 pt-3"
        >
          <div>
            <div class="member_item d-flex flex-column align-items-center">
              <div>
                <img
                  class="country-img"
                  loading="lazy"
                  src="{{ country.flag }}"
                  alt="country"
                />
              </div>
              <div class="name m-3 f_18 fw-bold">
                {{ country.name }}
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<section class="mt-5 pt-5">
  <div class="container">
    <h2 class="ab_sec_heading text-center">Our Strength</h2>
    <div class="strength_image">
      <img
        src="https://images.prismic.io/kurmishadi/ZkW17Col0Zci9MHo_ourStrength.webp?auto=format,compress?auto=compress,format"
        alt=""
      />
    </div>
  </div>
</section>
<!-- our mission and vision -->

<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mt-5 vision_mission">
        <div class="circle_yellow"></div>
        <div class="yellow">
          <div class="icon_img">
            <img src="../../assets/images/vision.png" alt="" />
          </div>
          <h2>Our Vision</h2>
          <p>
            To empower businesses and individuals through innovative technology
            solutions, fostering resilience and growth in an ever-evolving
            digital landscape. We envision a future where technology seamlessly
            integrates with human ingenuity, enabling organizations to thrive
            and make a meaningful impact.
          </p>
        </div>
      </div>
      <div class="col-lg-6 mt-5 vision_mission">
        <div class="circle_blue"></div>
        <div class="blue">
          <div class="icon_img">
            <img src="../../assets/images/mission.png" alt="" />
          </div>
          <h2>Our Mission</h2>
          <p>
            To design, develop, and deliver exceptional software and web
            solutions that meet the unique needs of our clients, exceeding their
            expectations and driving their success. Delivering top-notch
            solutions that meet the highest standards of quality and innovation.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="mt-5 pt-5">
  <div class="container">
    <div class="row">
      <div class="strength_image col-lg-6 col-md-6">
        <img src="assets/images/ourvision.png" />
      </div>
      <div class="strength_image col-lg-6 col-md-6">
        <img src="assets/images/ourmission.png" />
      </div>
    </div>
  </div>
</section> -->

<!-- -------------------------Strength End----------------- -->

<!-- -------------------------Vision Start----------------- -->
<!-- <section id="abt_intro">
  <div class="container">
    <h2 class="vision_mission">Our Vision & Mission</h2>
    <div class="row intro_holder">
      <div class="col-lg-6 col-12">
        <div class="service_item vision animate__animated show-on-scroll animate__bounceIn coloured p-5">
          <h2 class="ab_sec_heading  text-center">Vision</h2>
          <p class="text-center">
            To empower businesses and individuals through innovative technology
            solutions, fostering resilience and growth in an ever-evolving
            digital landscape. We envision a future where technology seamlessly
            integrates with human ingenuity, enabling organizations to thrive
            and make a meaningful impact.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="service_item animate__animated show-on-scroll animate__bounceIn coloured p-5">
          <h2 class="ab_sec_heading  text-center">Mission</h2>
          <p class="text-center">
            To design, develop, and deliver exceptional software and web solutions that meet the unique needs of our
            clients, exceeding their expectations and driving their success. We're committed to:
          </p>
          <p class="text-center">Delivering top-notch solutions that meet the highest standards of quality and
            innovation</p> -->
<!-- <p>Building long-lasting relationships with our clients, founded on trust, transparency, and open communication</p> -->
<!-- <p>Fostering a culture of continuous learning, creativity, and collaboration within our team</p>
          <p>Embracing the latest technologies and trends to stay ahead of the curve</p> -->
<!-- </div>
      </div>
    </div>
  </div>
</section> -->

<!-- -------------------------Vision End----------------- -->

<!-- -------------------------Mission  Start----------------- -->
<!-- <section id="abt_intro">
  <div class="container">
    <div class="intro_holder">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div
          class="animate__animated show-on-scroll animate__bounceIn white p-5"
        >
          <h2 class="ab_sec_heading text-center">Our Mission</h2>
          <div class="row">
            <div class="col-lg-12">
              <p>
                To design, develop, and deliver exceptional software and web
                solutions that meet the unique needs of our clients, exceeding
                their expectations and driving their success. We're committed
                to:
              </p>
            </div>
            <div class="col-lg-3 gx-2 text-center">
              <div class="mission">
                <div class="background-image mission-one-img"></div>
                <div class="overlay"></div>
                <span
                  >Delivering top-notch solutions that meet the highest
                  standards of quality and innovation</span
                >
              </div>
            </div>
            <div class="col-lg-3 gx-2 text-center">
              <div class="mission">
                <div class="background-image mission-two-img"></div>
                <div class="overlay"></div>
                <span
                  >Building long-lasting relationships with our clients, founded
                  on trust, transparency, and open communication</span
                >
              </div>
            </div>

            <div class="col-lg-3 gx-2 text-center">
              <div class="mission">
                <div class="background-image mission-three-img"></div>
                <div class="overlay"></div>
                <span
                  >Fostering a culture of continuous learning, creativity, and
                  collaboration within our team</span
                >
              </div>
            </div>
            <div class="col-lg-3 gx-2 text-center">
              <div class="mission">
                <div class="background-image mission-four-img"></div>
                <div class="overlay"></div>
                <span
                  >Embracing the latest technologies and trends to stay ahead of
                  the curve</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- -------------------------Mission End----------------- -->
<!-- -------------------------Our Values Start----------------- -->
<section id="abt_intro">
  <div class="container">
    <div class="intro_holder">
      <div class="white p-5 text-center">
        <h2 class="value-heading">Our Values</h2>
        <!-- <div class="row"> -->
        <div class="row">
          <div class="col-lg-3 gx-5 text-center">
            <div class="value innovation">
              <div class="white_shadow">
                <div class="fw-800 text-center our-values-heading">
                  Innovation
                </div>
                <span> We embrace creativity and continuous learning</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 gx-5 text-center">
            <div class="value excellence">
              <div class="white_shadow">
                <div class="fw-800 text-center our-values-heading">
                  Excellence
                </div>
                <span> We strive for perfection in everything we do.</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 gx-5 text-center">
            <div class="value collab">
              <div class="white_shadow">
                <div class="fw-bold text-center our-values-heading">
                  Collaboration
                </div>
                <span>We work together as a team to achieve shared goals.</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 gx-5 text-center">
            <div class="value integrity">
              <div class="white_shadow">
                <div class="fw-800 text-center our-values-heading">
                  Integrity
                </div>
                <span
                  >We operate with transparency and honesty in all our
                  interactions.</span
                >
              </div>
            </div>
          </div>

          <div class="col-lg-3 gx-5 text-center">
            <div class="value customer">
              <div class="white_shadow">
                <div class="fw-800 text-center our-values-heading">
                  Customer-centricity
                </div>
                <span>
                  We prioritize our clients' needs and success above all
                  else.</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-3 gx-5 text-center">
            <div class="value agility">
              <div class="white_shadow">
                <div class="fw-800 text-center our-values-heading">Agility</div>
                <span
                  >We adapt quickly to changing requirements and
                  circumstances.</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-3 gx-5 text-center">
            <div class="value accountablity">
              <div class="white_shadow">
                <div class="fw-800 text-center our-values-heading">
                  Accountability
                </div>
                <span
                  >We take ownership of our work and are responsible for the
                  outcomes.</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-3 gx-5 text-center">
            <div class="value improvement">
              <div class="white_shadow">
                <div class="fw-800 text-center our-values-heading">
                  improvement
                </div>
                <span
                  >Continuous pursuit of process and performance enhancements to
                  deliver superior IT solutions.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- -------------------------Our Values End----------------- -->

<!------------------------What We Do Start----------------------->
<section id="abt_intro">
  <div class="container">
    <div class="intro_holder">
      <div class="white p-5 text-center">
        <h2 class="ab_sec_heading">What We Do</h2>
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div
              class="service_item animate__animated show-on-scroll animate__bounceIn p_20"
              routerLink="service/website-Design"
              animate-order="1"
            >
              <img
                loading="lazy"
                class="service_icon"
                src="https://images.prismic.io/kurmishadi/0d4f835c-2904-410a-a9a3-6f78aba1d68e_web-design.png?auto=compress,format=webp,height=60,width=60"
                alt="Web Designing"
              />
              <div class="text-center fw-bold mt-2">
                Custom Software Development
              </div>
              <span>
                Tailored solutions to meet your unique business needs</span
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div
              class="service_item animate__animated show-on-scroll animate__bounceIn p_20"
              routerLink="service/Web-Application-Development"
            >
              <img
                loading="lazy"
                class="service_icon"
                src="https://images.prismic.io/kurmishadi/c4c03523-ced2-46cb-a731-a0f75fb6a2f6_web-development.png?auto=compress,format=webp,height=60,width=60"
                alt="Web-development"
              />
              <!-- <span class="service_name">Web Development</span> -->
              <div class="text-center fw-bold">Web Design and Development</div>
              <span
                >Responsive, user-friendly websites that drive engagement</span
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div
              class="service_item animate__animated show-on-scroll animate__bounceIn p_20"
              routerLink="service/android-app-development"
            >
              <img
                loading="lazy"
                class="service_icon"
                src="https://images.prismic.io/kurmishadi/26af67ef-fd85-48f6-ae1d-0b9c3006f191_apps.png?auto=compress,format=webp,height=60,width=60"
                alt="Mobile"
              />
              <div class="text-center fw-bold mt-2">
                Application Development
              </div>
              <span
                >Mobile and desktop applications that streamline processes</span
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div
              class="service_item animate__animated show-on-scroll animate__bounceIn p_20"
              routerLink="service/digital-Media-Marketing"
            >
              <img
                loading="lazy"
                class="service_icon"
                src="https://images.prismic.io/kurmishadi/243de374-b89a-4bd7-9c0b-496bae48cdf4_social-media.png?auto=compress,format=webp,height=60,width=60"
                alt="Social-media"
              />
              <!-- <span class="service_name">Digital Marketing</span> -->
              <div class="text-center fw-bold mt-2">Digital Marketing</div>
              <span
                >Strategic marketing solutions to reach your target
                audience</span
              >
            </div>
          </div>
          <div class="col-lg- col-md-4">
            <div
              class="service_item animate__animated show-on-scroll animate__bounceIn p_20"
              routerLink="service/search-Engine-Optimisation"
            >
              <img
                loading="lazy"
                class="service_icon"
                src="https://images.prismic.io/kurmishadi/6d7714cd-2070-45e5-8423-0bf2838ca2bd_seo.png?auto=compress,format=webp,height=60,width=60"
                alt="seo"
              />
              <!-- <span class="service_name">SEO Solution</span> -->
              <div class="text-center fw-bold mt-2">SEO</div>
              <span>Expert optimization to improve your online visibility</span>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div
              class="service_item animate__animated show-on-scroll animate__bounceIn p_20"
              routerLink="service/bulk-Sms-and-Email-Marketing"
            >
              <img
                loading="lazy"
                class="service_icon"
                src="https://images.prismic.io/kurmishadi/31fa9ad8-bc17-4d7f-9fa3-fa80f3cfc06b_email_next.png?auto=compress,format=webp,height=60,width=60"
                alt="email"
              />
              <!-- <span class="service_name">E-mail Services</span> -->
              <div class="text-center fw-bold">IT Consulting</div>
              <span
                >Expert guidance to help you make informed technology
                decisions</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--------------------------------------What We Do End------------------------------------------->

<!-------------------------------------Our Expertise Start---------------------------------------------->
<section id="abt_intro">
  <div class="container">
    <div class="intro_holder">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div
          style="padding: 10px"
          class="animate__animated show-on-scroll animate__bounceIn white"
        >
          <div class="expertise">
            <h1 class="ab_sec_heading text-center">Our Expertise</h1>
            <div class="my-3 d-flex expertiseList">
              <!-- <div class="expertise_option mt-2">Angular</div> -->
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8MCol0Zci9ML__angular.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8hSol0Zci9MMS_react.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8UCol0Zci9MMG_mango_db.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8Zyol0Zci9MMN_php.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8RSol0Zci9MME_laravel.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8Nyol0Zci9MMA_ionic.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8Kiol0Zci9ML9_android.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8Piol0Zci9MMC_ios.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8Viol0Zci9MMI_MySQL.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8YCol0Zci9MMK_node.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8fyol0Zci9MMR_python.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW-ACol0Zci9MNq_14.png?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8eiol0Zci9MMQ_ps.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/ZkW8bSol0Zci9MMO_pI_copy.webp?auto=compress,format"
                  alt=""
                />
              </div>
              <div class="expertise_option mt-2">
                <img
                  src="https://images.prismic.io/kurmishadi/Z8bmJxsAHJWomFvB_Google-flutter-logo.png?auto=format,compress"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-------------------------------------Our Expertise End---------------------------------------------->

<!-------------------------------------Our Team Start------------------------------------------------------->
<!-- <section id="abt_intro">
  <div class="container">
    <div class="intro_holder">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div style="padding: 10px" class="animate__animated show-on-scroll animate__bounceIn white p-5">
          <div>
            <h1 class="ab_sec_heading text-center">Our Team</h1>
            <p class="fw-bold text-center">
              Our team of experts includes experienced developers, designers,
              marketers, and project managers who work together to deliver
              exceptional results. We're passionate about what we do and are
              dedicated to helping our clients succeed.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-----------------------------------------Our Team End--------------------------------------------------------->

<!-- <p>
        ResilienceSoft is a global business advisory firm that provides
        multidisciplinary solutions to complex challenges and opportunities.
        With the full power of unique depth of thought combined with the global
        expertise of leading professionals, we are committed to protecting and
        enhancing the enterprise value of our clients.
      </p> -->
<!-- <p>
        ResilienceSoft is a leading brand in Web Design and Software Development
        organization. ResilienceSoft doing extensive work in the field of
        Website Designing, Website Development and SEO. Based in the Bilaspur,
        (Chhattisgarh), our Web Development and SEO Company is committed to
        provide work which pushes the limits of creativity and at a quick
        turnaround time. -->
<!-- </p> -->

<!-- -------------------------Intro Section End----------------- -->

<!-- -------------------------Office Section Start----------------- -->

<section class="pb-5" id="abt_office">
  <div class="container">
    <h2 class="ab_sec_heading text-center">Our Team</h2>
    <div class="about_gallery">
      <app-gallerymodel [GalleryData]="picArray"></app-gallerymodel>
    </div>
  </div>
</section>

<!-- -------------------------Office Section End----------------- -->

<!-- -------------------------Team Section Start----------------- -->

<section id="about_leaders" class="mb-5">
  <div class="container">
    <h2 class="ab_sec_heading text-center">Team Leaders</h2>
    <div id="our_leaders">
      <ngx-slick-carousel
        class="constant-simple-slider mt-4"
        #slickModal="slick-carousel"
        [config]="expertsConfig"
      >
        <div ngxSlickItem *ngFor="let expert of experts" class="slide">
          <div class="crousel-slide">
            <div class="member_item">
              <img loading="lazy" src="{{ expert.img }}" alt="expert" />
              <span class="name">{{ expert.tittle }}</span>
              <span class="position">{{ expert.position }}</span>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<!-- -------------------------Team Section End----------------- -->

<!----------------------------Our Customer Start------------------>
<!-- <section id="abt_intro">
  <div class="container">
    <div class="intro_holder">
      <h2 class="ab_sec_heading text-center">Our Clients</h2>
      <p>
        At ResilienceSoft, we take pride in our diverse client base, spanning
        various industries and sectors. Our clients trust us to deliver
        innovative technology solutions that meet their unique needs and drive
        their success. Here are some of our esteemed clients:
      </p>
      <div class="my-2 mx-2 d-flex expertiseList">
        <div class="expertise_option">Healthcare</div>
        <div class="expertise_option">Finance: details of clients</div>
        <div class="expertise_option">Education:</div>
        <div class="expertise_option">Retail:</div>
        <div class="expertise_option">Manufacturing:</div>
        <div class="expertise_option">Government:</div>
        <div class="expertise_option">Social media</div>
      </div>
      <div class="m-t">
        <p class="fw-bold">
          We have helped our clients achieve significant benefits, including:
        </p>
        <p>- Improved efficiency and productivity</p>
        <p>- Enhanced customer experience</p>
        <p>- Increased revenue and growth</p>
        <p>- Better decision-making with data-driven insights</p>
        <p>- Enhanced security and compliance</p>
      </div>
    </div>
  </div>
</section> -->

<!-- .................................................................. -->
<section class="feedback_sec">
  <div class="container">
    <div class="section_heading">
      <h2 style="padding-top: 20px" class="text-light m-t">
        REVIEW FROM CLIENTS
        <img
          loading="lazy"
          class="feedback_heart"
          src="assets/images/heart.webp"
          alt="heart"
        />
      </h2>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <div id="feedback">
          <div class="testimonial">
            <ngx-slick-carousel
              class="constant-simple-slider mt-4"
              #slickModal="slick-carousel"
              [config]="slideConfigmsg"
            >
              <div
                ngxSlickItem
                *ngFor="let feedback of clientFeedback"
                class="slide"
              >
                <div class="content">
                  <p class="description">{{ feedback.description }}</p>
                </div>
                <div class="testimonial-reviews">
                  <div class="testimonial-title">
                    <div class="d-flex justify-content-between pr-2">
                      <div class="f_20">{{ feedback.title }}</div>
                      <div>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                      </div>
                    </div>
                    <div>
                      <h3>{{ feedback.country }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- .......................................................................... -->
<section id="abt_intro">
  <div class="container">
    <div class="intro_holder">
      <h2 class="ab_sec_heading text-center fs-40">Get in Touch</h2>
      <p>
        Ready to learn more about how ResilienceSoft can help your business
        thrive? Contact us today to schedule a consultation and discover the
        power of innovative technology solutions
      </p>
      <div class="row mrb-40">
        <div class="col-lg-6 col-xl-4">
          <div class="contact-block d-flex mrb-30">
            <div class="contact-icon-container">
              <a>
                <img
                  loading="lazy"
                  class="contact-icon"
                  src="/assets/images/office-icon.png"
                  alt="office-icon"
                />
              </a>
            </div>

            <div class="contact-details mrl-30">
              <h5 class="icon-box-title mrb-10">Our Address</h5>
              <p class="mrb-0">
                <a
                  >2nd Floor Emerald Plaza, Opposite Vinayak Netralaya, Near
                  Agrasen Chowk, Link Road Bilaspur C.G.</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4">
          <div class="contact-block d-flex mrb-30">
            <div class="contact-icon-container">
              <a href="tel:07752-414580">
                <img
                  loading="lazy"
                  class="contact-icon"
                  src="/assets/images/telephone1.png"
                  alt="telephone"
                />
              </a>
            </div>

            <div class="contact-details mrl-30">
              <h5 class="icon-box-title mrb-10">Phone Number</h5>
              <p class="mrb-0">
                <a class="contact-number-office" href="tel:9109911372"
                  >9109911372</a
                >
                &nbsp;,
                <a class="contact-number-office" href="tel:9201996377"
                  >9201996377</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4">
          <div class="contact-block d-flex mrb-30">
            <div class="contact-icon-container">
              <a href="mailto:info@resiliencesoft.com">
                <img
                  loading="lazy"
                  class="contact-icon"
                  src="/assets/images/mail1.png"
                  alt="mail"
                />
              </a>
            </div>

            <div class="contact-details mrl-30">
              <h5 class="icon-box-title mrb-10">Email Us</h5>
              <p class="mrb-0">
                <a
                  class="contact-number-office"
                  href="mailto:info@resiliencesoft.com"
                >
                  info&#64;resiliencesoft.com
                </a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <app-call-back></app-call-back>
    </div>
  </div>
</section>

<!-- ........................................................................... -->

<!-- <section>
  <video width="100%" controls autoplay="true">
    <source src="assets/images/video.mp4" type="video/mp4" />
    <source src="assets/images/video.ogg" type="video/ogg" />
  </video>
</section> -->


<!-- -------------------------Video Section Start----------------- -->


<!-- ........................................................................... -->
