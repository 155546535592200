import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meettheteam',
  templateUrl: './meettheteam.component.html',
  styleUrls: ['./meettheteam.component.css'],
})
export class MeettheteamComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  meetTheTeam = [
    {
      img: 'https://images.prismic.io/kurmishadi/530015ce-677b-4bbb-84d4-d0dc7605bce9_pankaj.jpg?auto=format,compress?auto=compress,format',
      name: 'Pankaj Mishra',
      designation: 'Director  CEO',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/d81c324c-501f-4d0d-8d41-40ec4e795b5f_prateek.jpg?auto=format,compress?auto=compress,format',
      name: 'Prateek Saluja',
      designation: 'Director  CEO',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWn3aWtHYXtT14Q_sushant-saluja.webp?auto=format,compress?auto=compress,format',
      name: 'Sushant Saluja',
      designation: 'Functional Manager',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/65de2683-a34a-4e43-8804-a5fef77bdd56_deepak.jpg?auto=format,compress?auto=compress,format',
      name: 'Deepak Verma',
      designation: 'Functional Manager',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/0fc62679-7b52-41c8-ae81-51d7ec8a01e7_irshad.jpg?auto=format,compress?auto=compress,format',
      name: 'Irshad Alam',
      designation: 'Senior Web Designer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/63828c9d-5c63-4022-a098-2ab1bc80e410_shreya.jpg?auto=format,compress?auto=compress,format',
      name: 'Shreya Gupta',
      designation: 'Senior PHP Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWoJ6WtHYXtT14d_arzoo.jpeg?auto=format,compress?auto=compress,format',
      name: 'Arzoo Khan',
      designation: 'Senior PHP Developer ',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/a9c3375c-8043-4424-842b-33d7622befcb_manish-chandra.webp?auto=compress,format=webp,height=200,width=200',
      name: 'Manish Chandra',
      designation: 'Senior React Native Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWpTKWtHYXtT15d_vikrant.webp?auto=format,compress?auto=compress,format',
      name: 'Vikrant Sangekar',
      designation: 'Senior .Net Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/45e2f9ca-80fb-4985-bc7b-86ab530e9db5_ankita.jpeg?auto=format,compress?auto=compress,format',
      name: 'Ankita Choudhary',
      designation: 'Senior Angular Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWp9aWtHYXtT158_devender.webp?auto=format,compress?auto=compress,format',
      name: 'Devender Sharma',
      designation: 'Senior .Net Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWq5qWtHYXtT16k_vaibhav.webp?auto=format,compress?auto=compress,format',
      name: 'Vaibhav Dubay',
      designation: 'Senior Angular Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/37e6f9b7-3dac-459e-ae3a-019d264cc6a9_sanya.webp?auto=compress,format=webp,height=200,width=200',
      name: 'Sanya Mishra',
      designation: 'React Native Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWrQKWtHYXtT16y_hemadri.webp?auto=format,compress?auto=compress,format',
      name: 'Hemadri Sahu',
      designation: 'Angular Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlW_oaWtHYXtT2KK_rakhi.jpg?auto=format,compress?auto=compress,format',
      name: 'Rakhi Dutta',
      designation: 'PHP Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/Zlmkh6WtHYXtT9q6_sandhaya.jpg?auto=format,compress?auto=compress,format',
      name: 'Sandhya Ojha',
      designation: 'Angular Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWuTqWtHYXtT19D_rashmi.png?auto=format,compress?auto=compress,format',
      name: 'Rashmi Garg',
      designation: 'Angular Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWpvKWtHYXtT154_niraj.webp?auto=format,compress?auto=compress,format',
      name: 'Niraj Shaw',
      designation: 'QA',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlmijKWtHYXtT9po_chiranjeev.jpeg?auto=format,compress?auto=compress,format',
      name: 'Chiranjiv Mohanty',
      designation: 'React Native Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/Zl2m5KWtHYXtUBY9_ayush.png?auto=format,compress?auto=compress,format',
      name: 'Ayush Ojha',
      designation: 'IT Sales',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlW45KWtHYXtT2GB_roshni.webp?auto=format,compress?auto=compress,format',
      name: 'Roshni Rathore',
      designation: 'PHP Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlXBjaWtHYXtT2MH_praveen.jpg?auto=format,compress?auto=compress,format',
      name: 'Praveen Nirmalkar',
      designation: 'React Native Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWrgaWtHYXtT167_twinkle.webp?auto=format,compress?auto=compress,format',
      name: 'Twinkle Dewangan',
      designation: 'Angular Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/Zl2fPqWtHYXtUBNC_durgesh.JPEG?auto=format,compress?auto=compress,format',
      name: 'Durgesh Gupta',
      designation: 'Web Designer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlW3C6WtHYXtT2E7_malti.png?auto=format,compress?auto=compress,format',
      name: 'Malti Kaushik',
      designation: 'Web Designer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlmtnKWtHYXtT9wV_pranjal.jpeg?auto=format,compress?auto=compress,format',
      name: 'Pranjal Dewangan',
      designation: 'QA',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlWr5aWtHYXtT17H_devashish.webp?auto=format,compress?auto=compress,format',
      name: 'Devasheesh Pilai',
      designation: 'Angular Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlXAMKWtHYXtT2Kv_yashwant_palke.png?auto=format,compress?auto=compress,format',
      name: 'Yashwant Palke',
      designation: 'PHP Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/Zlmj1aWtHYXtT9qr_kalmeshwar.JPEG?auto=format,compress?auto=compress,format',
      name: 'Kamleshwar Jangde',
      designation: '.Net Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlXET6WtHYXtT2NZ_Shruti2.png?auto=format,compress?auto=compress,format',
      name: 'Shruti Mourya',
      designation: 'React Native Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/Zlmlx6WtHYXtT9r7_rahul-dewangan.png?auto=format,compress?auto=compress,format',
      name: 'Rahul Dewangan',
      designation: 'Web Designer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZlXCJ6WtHYXtT2MY_khushi.jpg?auto=format,compress?auto=compress,format',
      name: 'Khusi Gupta',
      designation: 'React Native Developer',
    },
    {
      img: 'https://images.prismic.io/kurmishadi/ZuPSBbVsGrYSvUie_IMG-20240905-WA0003.jpg?auto=format,compress',
      name: 'Ranu Yadav',
      designation: 'Office Assistant',
    },
  ];
}
